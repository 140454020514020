body {
  font-family: "proxima-nova", serif;
  color: #0b1336;
}

::placeholder {
  color: #757575 !important;
  font-family: "proxima-nova", serif;
  font-weight: 400;
  font-size: 12px;
  opacity: 1 !important;
}

:root {
  --primary-color: #f5a623;
  --primary-color-light: #f5b54f;
  --primary-color-lightest: #f1c47c;

  --header-height: 53px;
}

.ant-layout {
  background-color: #ffffff;
}

/* hide expand button and row for non-expandable items */
.ant-table-row.no-expand > td > span.ant-table-row-expand-icon {
  display: none;
}
.ant-table-row.no-expand + tr.ant-table-expanded-row {
  display: none;
}

.ant-btn .ant-btn-primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--primary-color-light);
  border: 1px solid var(--primary-color-light);
}

.ant-btn-primary:active,
.ant-btn-primary.active {
  background-color: var(--primary-color-lightest);
  border: 1px solid var(--primary-color-lightest);
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
}

figure {
  margin: 0;
}

.register-fieldset .ant-form-item {
  margin-top: 26px !important;
}

.signin-fieldset .ant-form-item {
  margin-top: 36px !important;
}

.property-name {
  line-height: 80% !important;
}
